@import "vars";
@import "~normalize.css";

$primary: $primary-color;

/*$grid-gutter-width: 10px;
$card-spacer-x: 0.75rem;

$input-btn-padding-y: .375rem;
$input-btn-padding-x: .5rem;*/

@import "~bootstrap/scss/bootstrap";

//@import "~roboto-fontface";
//@import "~typeface-roboto-slab";

html,
body {
  position: fixed;
  overflow: hidden;
}

body .wrapper {
  overflow-y: auto;
  -webkit-overflow-scrolling: touch; /* enables “momentum” (smooth) scrolling */
}


body {
  font-family: "Roboto", sans-serif;
  font-size: 17px;
  line-height: 20px;
  //color: #C4C4C4;

  * {
    box-sizing: border-box;
  }
}

a {
  color: $primary-color;
}

h1 {
  margin: 0 0 0.5em;
  line-height: 1.42;
}

svg.svg-icon {
  max-width: 100%;
  max-height: 100%;
}

.section {
  $mobile-padding: 30px;
  $desktop-padding: 80px;


  padding-bottom: $mobile-padding;

  @media screen and (min-width: 1200px) {
    padding-bottom: $desktop-padding;
  }

  &.section--padding-top {
    padding-top: $mobile-padding;

    @media screen and (min-width: 1200px) {
      padding-bottom: $desktop-padding;
    }

  }

  &.section--no-padding {
    padding: 0;
  }

  &.section--no-padding-bottom {
    padding-bottom: 0;
  }

  &.section--bg-grey {
    background-color: $bg-grey;
  }

  &__title {
    font-size: 24px;
    font-weight: normal;
    margin: 0 0 1em 0;
  }

  &__title {
    font-size: 24px;
    font-weight: normal;
    margin: 0 0 1em 0;
  }

  &__title-big {
    margin-top: 0;
    font-size: 38px;
    font-weight: normal;
    margin-bottom: 0.67em;
  }
}

.admin-page-selector {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;

  &__container {
    border: 1px solid #dddddd;
    padding: 2em 3em;
    margin: 0;


    li {
      list-style-type: none;
    }
  }
}

@for $i from 1 through 4 {
  .mb-#{5 * $i} {
    margin-bottom: 5px * $i;
  }
}

//.btn {
//  display: inline-block;
//  text-decoration: none;
//  line-height: 1;
//  padding: 0.5em 1.25em;
//  border: 1px solid $grey-light;
//  color: $grey;
//  cursor: pointer;
//  transition: 0.3s ease all;
//  text-align: center;
//  outline: none;
//  border-radius: $base-border-radius;
//
//
//  &.is-hide {
//    display: none;
//  }
//
//  &.btn--sm {
//    padding: 5px 15px;
//  }
//
//  &.btn--block {
//    width: 100%;
//  }
//
//  &.btn--primary-color {
//    background-image: linear-gradient(to right, darken($primary-color, 5%), $primary-color, darken($primary-color, 5%));
//    color: white;
//    border-color: $primary-color;
//
//    &:hover {
//      color: $primary-color;
//      background-color: white;
//      background-image: none;
//    }
//  }
//
//  &.btn--grey-transparent-color {
//    border: 1px solid $grey-light;
//    background-color: transparent;
//    color: $grey;
//
//    &:hover {
//      color: $black;
//      background-color: $grey-light;
//    }
//  }
//
//  &.btn--primary-transparent-color {
//    border: 1px solid $primary-color;
//    background-color: transparent;
//    color: $primary-color;
//
//    &:hover {
//      color: white;
//      background-color: $primary-color;
//    }
//  }
//
//  &.btn--white-color {
//    border: 1px solid white;
//    background-color: transparent;
//    color: white;
//
//    &:hover {
//      color: $secondary-color;
//      background-color: white;
//    }
//  }
//}
